import React, { createContext } from "react";

type ThemeType = {
  theme: string;
  setTheme: (arg: string) => void;
}

export const ThemeContext = createContext<ThemeType>({ theme: "dark", setTheme: (_) => {} });

export const ThemeProvider = ({children}) => {
  const [theme, setTheme] = React.useState<string>("dark");

  return (
    <ThemeContext.Provider value={{theme, setTheme}}>
      {children}
    </ThemeContext.Provider>
  )
}